<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="style.color" v-bind="attrs" v-on="on">
        {{ style.icon }}
      </v-icon>
    </template>
    <span> {{ style.text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["superou"],
  data() {
    return {
      gmr: {
        S: {
          icon: "mdi-arrow-up-thick",
          color: "success",
          text: "Superou a Meta"
        },
        N: {
          icon: "mdi-arrow-down-thick",
          color: "warning",
          text: "Não superou a meta"
        }
      }
    };
  },
  computed: {
    style() {
      return this.gmr[this.superou];
    }
  }
};
</script>

<style></style>
